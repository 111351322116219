.player-controls-container {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  flex-direction: column;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 20px;
}

.mid-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-btn {
  padding: 20px 10px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.icons {
  position: relative;
  display: block;
  width: 24px;
}

.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.volume-container {
  display: flex;
  align-items: center;
}
