.video-bg {
  height: 100%;
  cursor: pointer;
}

.arrow-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0); /* Arrow is at its normal position */
  }
  40% {
    transform: translateY(-10px); /* Arrow is slightly lifted */
  }
}
