.bg-image-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 100%;
  aspect-ratio: 16/9;
}

.image-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.bg-image-container:hover .image {
  transform: scale(1.05);
}

.image {
  aspect-ratio: 1/1;
  object-fit: cover;
  transition: all 0.5s;
  width: 100%;
  height: 100%;
}
