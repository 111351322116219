.app-bar {
  padding: 0 10rem;
}

.links {
  padding: 0 16px;
  align-items: center;
}

.links .active {
}

.links .not-active {
  opacity: 0.6;
}
